/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Steps from "widgets/Steps/Steps";
import { ReactComponent as Logo } from 'assets/images/rein_logo.svg'
import { useFormBackMutationQuery } from 'entities/ApplicationFormBack/hooks/queries';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { usePolicyMutationQuery } from 'entities/Policy/hooks/queries';
import { StyledButton } from "shared/ui/Button/styles";
import {
	useCustomerDataUpdateMutationQuery,
	useCustomerJourneyMutationQuery,
	useJourneyQuery,
	useJourneyResultFormQuery
} from 'entities/ApplicationForm/hooks/queries';
import { useApplicationProcessQuery, useProcessMutationQuery } from 'entities/ApplicationProcess/hooks/queries';
import { policyPayload } from 'widgets/CheckoutForm/helper';
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import SummaryInfo from 'widgets/SummaryInfo';
import { useFileGetMutationQuery, useFileMutationQuery } from 'entities/Files/hooks/queries';
import { FileResponse, PolicyResponse } from 'shared/api/assurant/policy/types';
import { useResolutionQuery } from 'entities/Resolution/hooks/queries';
import HTMLModal from 'shared/ui/HTMLModal/HTMLModal';
import { DocumentType } from 'shared/config/enums/documents';
import { CustomerFormattedType, JourneyResult } from 'shared/config/types/customerResponse';

const SummaryPage = () => {
	const navigate = useNavigate();
	const { paymentData, query, formData } = useContext(FormDataContext);
	const { applicationAddress } = useContext(ApplicationFormContext);
	const { mutateAsync: createFile } = useFileMutationQuery();
	const { mutateAsync: getFileData } = useFileGetMutationQuery()
	const { plan } = formData;
	const [disclosuresBannerOpen, setDisclosuresBannerOpen] = useState<DocumentType | null>(null);
	const { isSuccess } = useFormBackMutationQuery();
	const { mutate: fetchForm } = useCustomerJourneyMutationQuery();
	const { mutate: getApplicationProcess, isSuccess: processSuccess } = useProcessMutationQuery();
	const { mutate: makePayment, isSuccess: paymentSuccess, isPending, data } = usePolicyMutationQuery();
	const { mutateAsync: updateData, isPending: isPendingCustomerUpdate } = useCustomerDataUpdateMutationQuery();
	const resolutionQuery = useResolutionQuery();
	const applicationProcessQuery = useApplicationProcessQuery();
	const customerData = useJourneyQuery();
	const journeyResultData = useJourneyResultFormQuery();
	const isSmall = useMediaQuery('(max-width:600px)');
	const applicationFormId = localStorage.getItem("applicationFormId");
	const moduleId = localStorage.getItem("moduleId");

	useEffect(() => {
		if (!paymentData) {
			navigate(-1);
		}
	}, [paymentData])
	const handleClose = () => {
		setDisclosuresBannerOpen(null)
	}
	const methods = useForm<IFormICheckoutInput>({
		defaultValues: {
			firstName: '',
			lastName: '',
			fullAddress: '',
			fullName: '',
			dateOfBirth: '',
			email: '',
			phoneNumber: '',
			alerts: false,
			policy: false,
			communityName: '',
			propertyRadio: 'sameAddress',
			billingAddressOption: 'useProperty',
			billingAddress: {
				address1: '',
				city: '',
				state: '',
				address2: '',
				lastName: '',
				zip: '',
				firstName: ''
			},
			paymentType: 'cc',
			payment: {
				cardName: '',
				cardCode: '',
				cardDate: '',
				cardNumber: '',
				bankAccountNumber: '',
				bankAccountNumConfirm: '',
				bankRoutingNumber: '',
			},
			mailingAddress: {
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: ''
			},
			mailingSave: '',
			billingSave: ''
		}
	});
	const { setError } = methods;

	useEffect(() => {
		if (isSuccess && applicationFormId) {
			getApplicationProcess(applicationFormId);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (processSuccess && applicationFormId) {
			const executionModuleId = applicationProcessQuery?.executingModuleId
			if (
				applicationProcessQuery?.applicationProcessStatus === "WaitingForUserInput"
			) {
				if (executionModuleId !== moduleId) {
					executionModuleId !== undefined && localStorage.setItem("moduleId", executionModuleId);
					fetchForm(applicationFormId);
					navigate(-2);
				} else {
					setTimeout(() => {
						getApplicationProcess(applicationFormId);
					}, 400);
				}
			} else {
				setTimeout(() => {
					getApplicationProcess(applicationFormId);
				}, 400);
			}
		}
	}, [processSuccess]);

	const goBack = () => {
		stepBack()
	}
	const stepBack = () => {
		navigate(-1);
	}
	const onSubmit = () => {
		if (!query) return;
		const uwAnswers = resolutionQuery?.pmsValues[0].value;
		const policyData = policyPayload(query, paymentData, plan, applicationAddress, uwAnswers)
		makePayment(policyData);
	}

	const updateDataAsync = useCallback(async (data: PolicyResponse, customerData: CustomerFormattedType, journeyResult?: JourneyResult) => {
		const queryNew = {
			...journeyResult,
			policy_result: data,
		}
		try {
			await updateData({
				customerJourneyId: customerData.customerJourneyId,
				stageName: "PolicyPurchase",
				stageId: "",
				model: JSON.stringify(queryNew),
			})
			navigate('/confirmation');
		} catch (e) {
			console.log('e: ', e);
		}
	}, []);
	useEffect(() => {
		if(paymentSuccess && data && data.data.transactionDetails.responseMessage) {
			if(data.data.transactionDetails.responseMessage.toLowerCase().includes('duplicate')) {
				window.location.href = 'https://renterscoverage.rein.ai/';
				return
			}
			navigate('/error');
			return
		}
		if (paymentSuccess && data && customerData) {
			void updateDataAsync(data.data, customerData, journeyResultData).then(r => r)
		}
	}, [paymentSuccess])

	const getFile = async (bytes: string): Promise<FileResponse | undefined> => {
		if (!bytes) return undefined
		const byteCharacters = atob(bytes);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const fileBlob = new Blob([byteArray], { type: 'application/pdf' });
		const file = new File([fileBlob], "PolicyDocument.pdf", { type: 'application/pdf' })
		const formData = new FormData();
		formData.append("file", file);
		const referenceId = localStorage.getItem('applicationFormId')
		if (referenceId) {
			try {
				const createdFileResponse = await createFile({ referenceId: referenceId, formData })
				if (createdFileResponse.data.id) {
					const { data } = await getFileData(createdFileResponse.data.id)
					return data
				}
				return undefined
			} catch (e) {
				console.log('e: ', e);
			}
		}
	}

	return (
		<Box>
			<Grid
				container
				spacing={0}
				sx={{ minHeight: '100vh' }}
			>
				<Grid item xs={12}>
					<Box sx={{ textAlign: 'center', position: 'relative' }} mt={5} mb={5}>
						<Typography
							variant="body1"
							color={"text.secondary"}
							fontSize={14}
							sx={{ mr: 1, cursor: 'pointer', position: 'absolute' }}
							onClick={stepBack}
						>
							Back
						</Typography>
						<Logo width={171} height={40}/>
						<Typography variant="body1" fontSize="10px" lineHeight="12px">Renters Insurance powered by Assurant®</Typography>
					</Box>
					<Steps/>
					<Container maxWidth={"lg"} sx={{ mt: 5 }}>
						<Box display="flex" alignItems="center" mb={2} justifyContent="center">
							<Typography variant={"h3"} align={"center"} fontSize={42} sx={{ mb: 0 }}>One Last Review</Typography>
						</Box>
						<SummaryInfo goBack={goBack} stepBack={stepBack}/>

						<Grid container mt={4}>
							<Grid item xs={12} md={8} lg={8}>
								<Grid container justifyContent={isSmall ? "center" : "end"}>
									<Grid item xs={6} md={6} lg={5}>
										<StyledButton
											loading={isPending || isPendingCustomerUpdate}
											variant="outlined"
											onClick={onSubmit}
										>
											PURCHASE NOW
										</StyledButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Box sx={{ mt: 5, mb: 5 }}>
							<Typography variant="body1" sx={{
								fontSize: '14px',
								lineHeight: '20px',
								'& a': {
									color: '#2780EC',
									cursor: 'pointer',
									textDecoration: 'none'
								}
							}}>
								By clicking "Purchase Now", I acknowledge that I have read and understand all applicable
								<a onClick={() => setDisclosuresBannerOpen("underwriters")}> underwriting</a>,
								<a onClick={() => setDisclosuresBannerOpen("legal")}> state disclosures</a>,
								<a onClick={() => setDisclosuresBannerOpen("fraud")}> fraud notices </a> &
								<a onClick={() => setDisclosuresBannerOpen("cancelationnotice")}> notice
									of
									cancellation</a>,
								and <a onClick={() => setDisclosuresBannerOpen("payauthorization")}>recurring payment authorization</a>.
							</Typography>
						</Box>
					</Container>
				</Grid>
			</Grid>
			{disclosuresBannerOpen && <HTMLModal
				open={!!disclosuresBannerOpen}
				handleClose={handleClose}
				type={disclosuresBannerOpen}
			/> }
		</Box>
	)
}

export default SummaryPage

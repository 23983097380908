import { styled } from "@mui/material/styles";

export const SidebarUI = styled('div', { shouldForwardProp: (propName) => propName !== 'sm' && propName !== 'sticky' })<{
	sm: boolean, sticky: boolean
}>(({ sticky, theme, sm }) => ({
	position: sticky ? 'fixed' : 'relative',
	top: sticky ? '30px' : '30%',
	width: sm ? '100%' : '300px',
	borderRadius: '8px',
	marginBottom: '10px',
	backgroundColor: "#FFF"
}))

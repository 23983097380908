import React, { useContext } from 'react'
import { BorderBlock, ListElement, ListLeftText, ListRightText } from "../styles";
import { Box, List, Typography } from "@mui/material";
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import dayjs from 'dayjs';
import { CoverageDetailsParameter } from 'shared/api/assurant/quoteResponseTypes';
import { AdditionalSmallItemIcon } from 'shared/ui/Accordion';
import CheckIcon from '@mui/icons-material/Check';
import numeral from 'numeral';

const periodsObj: Record<number, string> = {
	1: 'Annual',
	4: '4-pay',
	11: 'Monthly'
}

const coverageDesc: Record<string, string> = {
	'hurricaneDeductible': 'Hurricane Deductible',
	'floodIncluded': 'Flood Coverage',
	'petDamage': 'Pet Damage',
	'waterDamageLiability': 'Water Damage Liability',
	'liabilityAmount': 'Liability Amount',
	'personalPropertyAmount': 'Your Belongings',
	'replacementCost': 'Replacement Cost',
	'waterBackup': 'Water Sewer Backup',
	'earthquake': 'Earthquake',
	'deductible': 'Deductible',
	'identityFraud': 'Fraud Expense Protection',
}
const yesNoText: Record<string, string> = {
	'N': 'No',
	'Y': 'Added',
}
const dateMask = (value: string | Date) => {
	return dayjs(value).format('MM-DD-YYYY');
};
const includedArray = ['replacementCost', 'floodIncluded']
const CoverageInfo = () => {
	const { formData, protections, policyStartDate, query: queryState } = useContext(FormDataContext);
	const coverages = queryState?.coverageDetails.parameters
	const { plan } = formData;
	const state = queryState?.policyHolderDetails.insured.address.state
	const renderItem = (item: CoverageDetailsParameter) => {
		const number = numeral(item.value)
		numeral.zeroFormat(item.value ?? '');
		numeral.nullFormat(item.value ?? '');
		const currency = number.format('$0,0[.]00')
		return (
			<ListElement key={item.name}>
				<ListLeftText>{coverageDesc[item.name]}</ListLeftText>
				<ListRightText>
					{includedArray.includes(item.name) && (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<AdditionalSmallItemIcon sx={{ backgroundColor: "orange.main" }}>
								<CheckIcon />
							</AdditionalSmallItemIcon>
							<Typography fontWeight={800} textAlign="right">Included</Typography>
						</div>
					)}
					{!includedArray.includes(item.name) && item.value && (
						<Typography fontWeight={700} textAlign="right">{yesNoText[item.value] || currency}</Typography>
					)}
				</ListRightText>
			</ListElement>
		)
	}
	return (
		<Box>
			<BorderBlock>
				<Typography variant="h4">Coverage Info</Typography>
				<List>
					<ListElement>
						<ListLeftText>Coverage start Date</ListLeftText>
						<ListRightText>{dateMask(policyStartDate)}</ListRightText>
					</ListElement>
					<ListElement>
						<ListLeftText>Renters payment option</ListLeftText>
						<ListRightText>{periodsObj[plan.period]}</ListRightText>
					</ListElement>
					{coverages && coverages.map((item) => {
						if (item.name === 'hurricaneDeductible'
							|| item.name === 'earthquake'
							|| item.name === 'waterBackup'
							|| item.name === 'identityFraud'
							|| item.name === 'floodIncluded'
							|| item.name === 'petDamage'
							|| item.name === 'waterDamageLiability'
						) {
							if (item.name === 'earthquake' && state == 'CA' && item.value === 'Y') {
								return renderItem(item);
							}
							if (item.name === 'waterBackup' && item.value === 'Y') {
								return renderItem(item);
							}
							if (item.name === 'floodIncluded' && item.value === 'Y') {
								return renderItem(item);
							}
							if (item.name === 'waterDamageLiability' && item.value === 'Y' && state == 'NC') {
								return renderItem(item);
							}
							if (item.name === 'identityFraud' && item.value === 'Y') {
								return renderItem(item);
							}
							if (item.name === 'petDamage' && item.value === 'Y') {
								return renderItem(item);
							}
						} else {
							if (coverageDesc[item.name]) {
								return renderItem(item);
							}
						}
					})}
					{coverages && coverages.map((item) => {
						if (item.name === 'hurricaneDeductible' && state == 'FL') {
							return renderItem(item);
						}
					})}
				</List>
			</BorderBlock>
			<Box sx={{mt:2}}>
				<BorderBlock>
					<Typography variant="subtitle2" fontWeight="normal">Need Help? Text <a href="tel:857-214-7740">857-214-7740</a> for
						assistance</Typography>
				</BorderBlock>
			</Box>
		</Box>
	)
}

export default CoverageInfo

import { Grid, RadioGroup, Typography } from '@mui/material';
import RadioButton from 'shared/ui/RadioButton/RadioButton';
import { StyledButton } from 'shared/ui/Button/styles';
import ModalWindow from 'shared/ui/ModalWindow/ModalWindow';
import React from 'react';
interface Props {
	questionModal: boolean;
	onClose: () => void;
	questionAnswer: string;
	handleChange:  (event: React.ChangeEvent<HTMLInputElement>) => void
	onSubmit:  () => void
	isPending:  boolean
}
const ModalRIQuestion = (props: Props) => {
	const {onClose, questionModal, handleChange, questionAnswer, onSubmit, isPending} = props
	return (
		<ModalWindow open={questionModal} handleClose={onClose} header={
			<Typography variant="h4" mb={0}>Just covering the bases</Typography>
		}
		>
			<Grid item md={12} sm={12} xs={12}>
				<Typography variant="subtitle2" fontWeight="normal" >
					During the last 10 years, has any applicant been formally charged or convicted of any degree of crime or fraud,
					bribery or arson in connection with this or any other property?
				</Typography>
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<RadioGroup
					value={questionAnswer}
					onChange={handleChange}
					aria-label="icon-radio"
				>
					<Grid container spacing={2}>
						<Grid item md={6} sm={6} xs={12}>
							<RadioButton
								checked={questionAnswer === 'Y'}
								value={'Y'}
								label="YES"
								label2={""}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<RadioButton
								checked={questionAnswer === 'N'}
								value={'N'}
								label="NO"
								label2={""}
							/>
						</Grid>
					</Grid>
				</RadioGroup>
			</Grid>
			<Grid item md={4} xs={8} sm={6}>
				<StyledButton variant="outlined" onClick={onSubmit} loading={isPending}>
					CONTINUE
				</StyledButton>
			</Grid>
		</ModalWindow>
	)
}

export default ModalRIQuestion

import {styled} from "@mui/material/styles";
import {Slider} from "@mui/material";

export const StyledSlider = styled(Slider)(({theme}) => ({
	"& .MuiSlider-thumb ": {
		border: 0,
		backgroundColor: "#FFF"
	},
	"& .MuiSlider-mark": {
		width: 0,
		height: 0,
	},
	"& .MuiSlider-rail": {
		backgroundColor: "#FFF",
		height: '10px',
		opacity: 1
	},
	"& .MuiSlider-track" : {
		backgroundImage: theme.palette.gradient.secondary,
		border: '1px solid transparent',
		borderRadius: '4px',
		height: '10px',
	},
	'& .MuiSlider-markLabel': {
		color: theme.palette.darkGrey.main
	},
	"*[class*='MuiSlider-markLabel'][data-index='0']": {
		transform: 'none',
	},
	"*[class*='MuiSlider-markLabel'][data-index='1']": {
		transform: 'translateX(-100%)',
	}
}))

import { Grid, RadioGroup, Typography } from '@mui/material';
import RadioButton from 'shared/ui/RadioButton/RadioButton';
import { StyledButton } from 'shared/ui/Button/styles';
import ModalWindow from 'shared/ui/ModalWindow/ModalWindow';
import React from 'react';
interface Props {
	questionModal: boolean;
	onClose: () => void;
	questionAnswer: string;
	handleChange:  (event: React.ChangeEvent<HTMLInputElement>) => void
	onSubmit:  () => void
	isPending:  boolean
	isNeedToCreateOffer:  boolean
}
const ModalRIQuestion = (props: Props) => {
	const {onClose, questionModal, handleChange, questionAnswer, onSubmit, isPending, isNeedToCreateOffer} = props
	return (
		<ModalWindow open={questionModal} handleClose={onClose} header={
			<Typography variant="h4" mb={0}>Just covering the bases</Typography>
		}
		>
			<Grid item md={12} sm={12} xs={12}>
				<Typography variant="subtitle2" fontWeight="normal" >
					What is your rental property made of?
				</Typography>
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<RadioGroup
					value={questionAnswer}
					onChange={handleChange}
					aria-label="icon-radio"
				>
					<Grid container spacing={2}>
						<Grid item md={6} sm={6} xs={12}>
							<RadioButton
								checked={questionAnswer === 'N'}
								value={'N'}
								label="Wood Frame"
								label2={""}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<RadioButton
								checked={questionAnswer === 'Y'}
								value={'Y'}
								label="Masonry"
								label2={""}
							/>
						</Grid>
					</Grid>
				</RadioGroup>
			</Grid>
			<Grid item md={12} sm={12} xs={12} justifyContent="center">
				{isPending && isNeedToCreateOffer && (
					<Typography variant="h4" textAlign="center" mb={0}>
						Please stand by while we create a new quote for you.
					</Typography>
				)}
			</Grid>
			<Grid item md={4} xs={8} sm={6}>
				<StyledButton variant="outlined" onClick={onSubmit} loading={isPending}>
					CONTINUE
				</StyledButton>
			</Grid>
		</ModalWindow>
	)
}

export default ModalRIQuestion
